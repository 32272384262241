<template>
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="订单详情"
    ></el-page-header>
    <div class="select-container table-c">
      <table width="400" border="0" cellspacing="0" cellpadding="0">
        <tr>
          <td width="40%">订单编号</td>
          <td width="100%">{{ info.orderID }}</td>
        </tr>
        <tr>
          <td>用户</td>
          <td>{{ info.userName }}</td>
        </tr>
        <tr>
          <td>下单时间</td>
          <td>{{ info.createdTime }}</td>
        </tr>
        <tr>
          <td>会员卡号</td>
          <td>{{ info.userID }}</td>
        </tr>
        <tr>
          <td>订单状态</td>
          <td>{{ info.orderState }}</td>
        </tr>
        <tr>
          <td>付款时间</td>
          <td>{{ info.settleTime }}</td>
        </tr>
        <tr>
          <td>总件数</td>
          <td>{{ info.pieceNum }}</td>
        </tr>
        <tr>
          <td>总金额</td>
          <td>{{ info.priceTotal | formatNumber }} 元</td>
        </tr>
      </table>
      <table
        style="margin-left: 10%"
        width="500"
        border="0"
        cellspacing="0"
        cellpadding="0"
      >
        <template v-for="(item, index) in productTable">
          <tr v-if="index == 0">
            <td>商品名称</td>
            <td>商品数量</td>
            <td>商品价格</td>
          </tr>
          <tr>
            <td style="width: 40%" v-text="item.name"></td>
            <td v-text="item.num"></td>
            <td v-text="item.price"></td>
          </tr>
        </template>
      </table>
    </div>
  </el-card>
</template>
<script>
import axios from "axios";
import secret from "@/utils/secret.js";
export default {
  data() {
    return {
      info: "",
      productTable: [],
      fullscreenLoading: true,
    };
  },
  created() {
    const single = true;
    const orderID = this.$route.params.id;
    this.userKey = secret.Decrypt(localStorage.getItem("user_key"));
    let userKey = this.userKey;
    axios.post(this.GLOBAL.api + "/order", { single, orderID, userKey }).then((res) => {
      this.info = res.data[0];
      this.info.orderState =
        res.data[0].orderState == "S" ? "已完成" : "未完成";
      this.productTable = JSON.parse(this.info.productList);
      this.fullscreenLoading = false;
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 1% 3%;
  position: relative;
  display: flex;
}
.box-card {
  width: 100%;
  height: 100%;
}
.table-c table {
  border-right: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  color: #606266;
}
.table-c table td {
  border-left: 1px solid #ebeef5;
  border-top: 1px solid #ebeef5;
  color: #606266;
  padding: 2%;
}
</style>